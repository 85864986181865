import { CSSProperties, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import ReactMapGL, {
  Marker,
  ViewState,
  ViewStateChangeEvent,
} from "react-map-gl";
import { ReactComponent as Pin } from "../pin.svg";
import { EnvConfig } from "../EnvConfig";

interface Site {
  name: string;
  latitude: number;
  longitude: number;
  status: string;
}

const GRAPHQL = gql`
  query {
    sites {
      name
      latitude
      longitude
      status
    }
  }
`;

export const Map = () => {
  const iframeSpacing = 16; // Spacing to fit within the iframe https://www.netnod.se/dns/dnsnode-for-tlds
  const [viewStyle, setViewStyle] = useState<CSSProperties>({
    width: window.innerWidth - iframeSpacing,
    height: window.innerHeight - iframeSpacing,
  });
  const [viewport, setViewState] = useState<ViewState>({
    latitude: 18,
    longitude: 0,
    zoom: 1,
    bearing: 1,
    pitch: 1,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  });
  const { data = { sites: [] } } = useQuery(GRAPHQL);

  useEffect(() => {
    const onResize = () => {
      setViewStyle({
        width: window.innerWidth - iframeSpacing,
        height: window.innerHeight - iframeSpacing,
      });
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <ReactMapGL
      {...viewport}
      style={viewStyle}
      projection={{ name: "mercator", center: [18, 0] }}
      onMove={(e: ViewStateChangeEvent) => setViewState(e.viewState)}
      mapStyle="mapbox://styles/mapbox/light-v11?optimize=true"
      mapboxAccessToken={EnvConfig.MAPBOX_TOKEN}
    >
      {data.sites
        .filter(
          (site: Site) =>
            site.status === "active" &&
            Number.isFinite(site?.latitude) &&
            Number.isFinite(site?.longitude)
        )
        .map((site: Site) => (
          <Marker
            key={site.name}
            latitude={site.latitude}
            longitude={site.longitude}
            offset={[-5, -20]}
          >
            <Pin style={{ height: "40px", width: "40px" }} />
          </Marker>
        ))}
    </ReactMapGL>
  );
};

export default Map;
